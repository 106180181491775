import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { supabaseIntegration } from '@supabase/sentry-js-integration';
import { createClient } from '@supabase/supabase-js';

import {
	PUBLIC_SENTRY_DSN,
	PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
	PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
	PUBLIC_ENV,
	PUBLIC_SUPABASE_URL,
	PUBLIC_SUPABASE_ANON_KEY
} from '$env/static/public';

// Initialize Supabase client
const supabaseClient = createClient(PUBLIC_SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY);

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_ENV,
	release: 'solarstream@1.0.0', // Set explicit release version
	integrations: [
		Sentry.replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
		}),
		Sentry.browserProfilingIntegration(),
		supabaseIntegration(supabaseClient, Sentry, {
			tracing: true,
			breadcrumbs: true,
			errors: true
		}),
		Sentry.browserTracingIntegration({
			// Prevent duplicate spans by ignoring Supabase requests in browser tracing
			shouldCreateSpanForRequest: (url: { toString: () => string | string[]; }) => {
				return !url.toString().includes(PUBLIC_SUPABASE_URL);
			},
		})
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ["localhost", "127.0.0.1", /^https:\/\/(staging\.|test\.|)app\.solarstream\.ch\/api/, /.*supabase\.co\/.*/],
	replaysSessionSampleRate: Number(PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
	replaysOnErrorSampleRate: Number(PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
	profilesSampleRate: 1.0,
	// Enable debug mode in development
	debug: false //PUBLIC_ENV === 'local'
});

const error: HandleClientError = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);

	return {
		message: 'An unexpected error occurred.'
	};
};

export const handleError = Sentry.handleErrorWithSentry(error);
