import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95')
];

export const server_loads = [0,3,7,19,22,12,13,16,17,18];

export const dictionary = {
		"/[[locale]]/(authenticated)/accounts": [~38,[3,4]],
		"/[[locale]]/(authenticated)/accounts/[accountId]": [~39,[3,4,5]],
		"/[[locale]]/(authenticated)/app": [~40,[3,6]],
		"/[[locale]]/(authenticated)/app/integration/success": [90,[3,6]],
		"/[[locale]]/(authenticated)/app/welcome": [91,[3,6]],
		"/[[locale]]/(authenticated)/app/[accountId]": [~41,[3,6,7],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/contacts": [68,[3,6,7,19],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/contacts/[contactId]": [69,[3,6,7,19],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/dashboard": [~44,[3,6,7,11],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/devtools/docs": [70,[3,6,7,20],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/devtools/docs/[...slug]": [~71,[3,6,7,20],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/devtools/emailPreviews": [72,[3,6,7,21],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/devtools/emailPreviews/status/[template]": [~73,[3,6,7,21,22],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/devtools/emailPreviews/supabase/[template]": [~74,[3,6,7,21],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/devtools/integrations": [75,[3,6,7,23],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/devtools/integrations/elektroform": [~76,[3,6,7,23],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/files": [~45,[3,6,7,11,12],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/files/[folderGroup]": [~46,[3,6,7,11,12],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/files/[folderGroup]/[projectId]": [~47,[3,6,7,11,12,13],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/files/[folderGroup]/[projectId]/[...path]": [~48,[3,6,7,11,12,13],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/leads": [~49,[3,6,7,11,14],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/leads/[leadId]": [~50,[3,6,7,11,14],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(full)/planner": [~42,[3,6,7,9,10],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(full)/planner/[category]": [~43,[3,6,7,9,10],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects": [~51,[3,6,7,11,15],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/new": [~67,[3,6,7,11,15,16],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]": [~52,[3,6,7,11,15,16,17],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/applicationOrder/v1": [~55,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/buildingPermit/v1": [~56,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/connectionRequest/v1": [~57,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/constructionSafety/v1": [~58,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/informNeighbours/v1": [~59,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/installationIndicator/v1": [~60,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/measureControlPv/v1": [~62,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/measureControl/v1": [~61,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/powerOfAttorney/v1": [~63,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/pronovoProxy/v1": [~64,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/siNa/v1": [~65,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/subsidies/v1": [~66,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/(templates)/systemTask/v1": [~53,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(main)/projects/(detail)/[projectId]/[taskId]/[template]/v1": [~54,[3,6,7,11,15,16,17,18],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings": [~77,[3,6,7,24],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/account": [~78,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/branding": [~79,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/checklists": [~80,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/files": [~81,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/general": [~82,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/integrations": [~83,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/leads": [~84,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(my-profile)/preferences": [~87,[3,6,7,24,27],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(my-profile)/profile": [~88,[3,6,7,24,27],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/projects": [~85,[3,6,7,24,25],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(my-profile)/security": [~89,[3,6,7,24,27],[,,8]],
		"/[[locale]]/(authenticated)/app/[accountId]/(subnavigation)/settings/(company)/users": [~86,[3,6,7,24,25,26],[,,8]],
		"/[[locale]]/(auth-pages)/auth/auth-code-error": [30,[2]],
		"/[[locale]]/(auth-pages)/auth/check": [31,[2]],
		"/[[locale]]/(auth-pages)/auth/password/reset": [~32,[2]],
		"/[[locale]]/(auth-pages)/auth/password/set": [~33,[2]],
		"/[[locale]]/(auth-pages)/auth/password/update": [~34,[2]],
		"/[[locale]]/(auth-pages)/login": [~35,[2]],
		"/[[locale]]/(auth-pages)/logout": [~36,[2]],
		"/[[locale]]/(content)/privacy": [92],
		"/sentry-example": [95],
		"/[[locale]]/(auth-pages)/signup": [~37,[2]],
		"/[[locale]]/status/[projectId]": [~94,[28]],
		"/[[locale]]/(content)/terms": [93],
		"/[[locale]]": [29]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';